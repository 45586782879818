<template>
  <b-container fluid>
     <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouseInfoService.warehouseSpaceStatus') }}</h4>
      </template>
      <template v-slot:body>
        <b-row v-if="!authUser.warehouse_id">
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('globalTrans.organization')"
                label-for="org_id"
            >
              <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="organizationtList"
                  id="org_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_report.division')"
                label-for="division_id"
            >
              <b-form-select
                  plain
                  v-model="search.division_id"
                  :options="divisionList"
                  id="division_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="!authUser.warehouse_id">
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_config.region')"
                label-for="region_id"
            >
              <b-form-select
                  plain
                  v-model="search.region_id"
                  :options="regionList"
                  id="division_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_report.district')"
                label-for="district_id"
            >
              <b-form-select
                  plain
                  v-model="search.district_id"
                  :options="districtList"
                  id="district_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="!authUser.warehouse_id" xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_report.upazilla')"
                label-for="upazilla_id"
            >
              <b-form-select
                  plain
                  v-model="search.upazilla_id"
                  :options="upazilaList"
                  id="upazilla_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="union_id"
                :label="$t('globalTrans.union')"
            >
              <b-form-select
                  plain
                  v-model="search.union_id"
                  :options="unionList"
                  id="union_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="warehouse_category_id"
                :label="$t('warehouse_config.warehouse_category')"
            >
              <b-form-select
                  plain
                  v-model="search.warehouse_category_id"
                  :options="warehouseCategoryList"
                  id="warehouse_category_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="warehouse_id"
                :label="$t('warehouse_information.warehouse_name')"
            >
              <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-if="!authUser.warehouse_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-else
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col></b-col>
          <b-col>
            <b-button type="button"  class="float-right" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <b-overlay class="border shadow bg-white" :show="loading">
      <b-row v-if="showData" class="justify-content-center">
          <b-col md="2" sm="6" class="custom-box-shadow">
            <div class="bg-spring-green mt-3 p-3 rounded mb-3 text-center">
              <h4 class="text-white">{{ $n(totalFarmer, { useGrouping: false }) }}</h4>
              <h5 class="text-white d-block">{{ $t('warehouse_service_performance.farmerNo') }}</h5>
            </div>
          </b-col>
          <b-col md="2" sm="6" class="custom-box-shadow">
              <div class="bg-blue mt-3 p-3 rounded mb-3 text-center">
                  <h4 class="text-white">{{ $n(totalService, { useGrouping: false }) }}</h4>
                  <h5 class="text-white d-block">{{ $t('warehouse_service_performance.service') }}</h5>
              </div>
          </b-col>
            <b-col md="2" sm="6" class="custom-box-shadow">
              <div class="bg-success mt-3 p-3 rounded mb-3 text-center">
                  <h4 class="text-white">{{ $n(totalComplain, { useGrouping: false }) }}</h4>
                  <h5 class="text-white d-block">{{ $t('warehouse_service_performance.complain') }}</h5>
              </div>
          </b-col>
            <b-col md="2" sm="6" class="custom-box-shadow">
              <div class="bg-puple mt-3 p-3 rounded mb-3 text-center">
                  <h4 class="text-white">{{ $n(totalDelayService, { useGrouping: false })}}</h4>
                  <h5 class="text-white d-block">{{ $t('warehouse_service_performance.delay_service') }}</h5>
              </div>
          </b-col>
            <b-col md="4" sm="6" class="custom-box-shadow">
              <div class="bg-primary mt-3 p-3 rounded mb-3 text-center">
                  <h5 class="text-white" >{{ $t('warehouseInfoService.actualSpace') }} : {{ $n(actualSpace, { useGrouping: false })}} <br/> {{ $t('warehouseInfoService.freeSpace') }} : {{ $n(freeSpace, { useGrouping: false })}}</h5>
              </div>
          </b-col>
      </b-row>
    </b-overlay>

    <b-row>
      <b-col md="12">
        <iq-card>
          <template>
            <b-row>
              <b-col lg="12" v-if="showData">
                <b-overlay class="border shadow" :show="loading">
                  <h5 class="text-center">{{ $t('warehouseInfoService.warehouseSpaceStatus')}}</h5>
                  <apexchart class="chart-1 text-center px-5" id="vuechart-example" type="bar" height="400" :options="dataOption" :series="series"></apexchart>
                </b-overlay>
              </b-col>
              <b-col lg="12" class="mt-4">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{  $t('warehouse_config.warehouseStockStatus') }}</h4>
                    </template>
                  <template v-slot:body>
                    <b-row v-if="!authUser.warehouse_id">
                      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('globalTrans.organization')"
                            label-for="org_id2"
                        >
                          <b-form-select
                              plain
                              v-model="search2.org_id"
                              :options="organizationtList"
                              id="org_id2"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('warehouse_report.division')"
                            label-for="division_id2"
                        >
                          <b-form-select
                              plain
                              v-model="search2.division_id"
                              :options="divisionList"
                              id="division_id2"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="!authUser.warehouse_id">
                      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('warehouse_config.region')"
                            label-for="region_id2"
                        >
                          <b-form-select
                              plain
                              v-model="search2.region_id"
                              :options="regionList"
                              id="region_id2"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('warehouse_report.district')"
                            label-for="district_id2"
                        >
                          <b-form-select
                              plain
                              v-model="search2.district_id"
                              :options="districtList2"
                              id="district_id2"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col v-if="!authUser.warehouse_id" xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('warehouse_report.upazilla')"
                            label-for="upazilla_id"
                        >
                          <b-form-select
                              plain
                              v-model="search2.upazilla_id"
                              :options="upazilaList2"
                              id="upazilla_id"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="!authUser.warehouse_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="union_id"
                            :label="$t('globalTrans.union')"
                        >
                        <b-form-select
                            plain
                            v-model="search2.union_id"
                            :options="unionList2"
                            id="union_id"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="!authUser.warehouse_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="warehouse_category_id2"
                            :label="$t('warehouse_config.warehouse_category')"
                        >
                          <b-form-select
                              plain
                              v-model="search2.warehouse_category_id"
                              :options="warehouseCategoryList"
                              id="warehouse_category_id2"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="warehouse_id"
                            :label="$t('warehouse_information.warehouse_name')"
                        >
                          <b-form-select
                              plain
                              v-model="search2.warehouse_id"
                              :options="warehouseList2"
                              id="warehouse_id"
                              v-if="!authUser.warehouse_id"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <b-form-select
                              plain
                              v-model="search2.warehouse_id"
                              :options="warehouseList2"
                              id="warehouse_id"
                              v-else
                          >
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col></b-col>
                      <b-col>
                        <b-button type="button"  class="float-right" variant="primary" @click="searchData2">{{ $t('globalTrans.search')}}</b-button>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
                <b-overlay class="border shadow" :show="loading2">
                  <div v-if="showData2">
                    <h5 class="text-center">{{ $t('warehouse_config.warehouseStockStatus')}}</h5>
                    <apexchart class="chart-1 text-center px-5" ref="stockStatusChart" id="stockStatusChart" type="bar" height="400" :options="stockStatusChartOptions" :series="stockStatusSeries"></apexchart>
                  </div>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { warehouseSpaceInfoApi, warehouseStockStatusApi } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  data () {
    return {
      showData: false,
      showData2: false,
      totalService: '',
      totalComplain: '',
      totalDelayService: '',
      totalSpace: '',
      totalFarmer: '',
      actualSpace: '',
      freeSpace: '',
      filledSpace: '',
      total_space: '',
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 13,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        warehouse_category_id: 0,
        crop_type_id: '0',
        crop_id: '0'
      },
      search2: {
        org_id: 13,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        warehouse_category_id: 0
      },
      regionLists: [],
      districtList: [],
      districtList2: [],
      upazilaList: [],
      upazilaList2: [],
      unionList: [],
      unionList2: [],
      warehouseList: [],
      warehouseList2: [],
      cropNameList: [],
      farmerList: [],
      loading: false,
      loading2: false,
      stockStatusSeriesVacantData: [],
      stockStatusSeriesFillData: [],
      stockStatusCategoriesData: [],
      // series: [{
      //   name: 'Total:',
      //   data: [45, 70, 49, 60]
      // }],
      seriesData: [45, 70, 49, 60],
      stockStatusSeries: [{
        name: 'Vacant',
        data: []
      }, {
        name: 'Fill',
        data: []
      }],
      stockStatusChartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
          width: '10%'
        },
        colors: ['#7F80FC', '#FD7171'],
        plotOptions: {
          bar: {
            columnWidth: '70%'
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 0,
              offsetY: 0
            }
          }
        }],
        xaxis: {
          categories: []
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val + '%'
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50
        }
      }
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    regionList: function () {
      return this.$store.state.warehouse.regionList
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList
    },
    warehouseCategoryList: function () {
      return this.$store.state.warehouse.warehouseCategoryList
    },
    cropTypeList: function () {
      return this.$store.state.warehouse.commodityGroupList
    },
    currentLocale () {
      return this.$i18n.locale
    },
    series: function () {
      return [{
        name: this.$t('sitePreference.total'),
        data: this.seriesData
      }]
    },
    dataOption () {
      return {
        chart: {
          id: 'vuechart-example',
          type: 'bar'
        },
        plotOptions: {
          bar: {
            barHeight: '50px',
            columnWidth: '40%',
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: 'bottom',
              horizontalAlign: 'center'
            }
          }
        },
        labels: [
          this.$t('warehouseInfoService.totalSpace'),
          this.$t('warehouseInfoService.actualSpace'),
          this.$t('warehouseInfoService.filledSpace'),
          this.$t('warehouseInfoService.freeSpace')
        ],
        colors: ['#666666', '#3333cc', '#ff0000', '#009900']
      }
    },
    ...mapGetters({
      defaultAuthorizedURIs: 'Auth/getAuthorizedUris',
      menus: 'Setting/getMenus'
    })
  },
  watch: {
    'search.region_id': function (newVal, oldVal) {
      this.districtList = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.crop_type_id': function (newVal, oldVal) {
      this.cropNameList = this.getCropNameList(newVal)
    },
    'search2.region_id': function (newVal, oldVal) {
      this.districtList2 = this.getRegionDistrictList2(newVal)
    },
    'search2.district_id': function (newVal, oldVal) {
      this.upazilaList2 = this.getUpazilaList(newVal)
    },
    'search2.upazilla_id': function (newVal, oldVal) {
        this.unionList2 = this.getUnionList(newVal)
      this.warehouseList2 = this.getWarehouseName(newVal, 'UPZ')
    },
    'search2.union_id': function (newVal, oldVal) {
      this.warehouseList2 = this.getWarehouseName(newVal, 'UP')
    }
  },
  created () {
     if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.warehouseList2 = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
      this.search2.warehouse_id = warehouseId
    }
  },
  mounted () {
    core.index()
    this.loadData()
    if (this.authUser.warehouse_id) {
      this.loadData2()
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.loading = true
      const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
      if (response.success) {
        this.farmerList = response.data
      }
      const result = await RestApi.getData(warehouseServiceBaseUrl, warehouseSpaceInfoApi, this.search)
      if (result.success) {
        this.totalService = result.data.totalService
        this.totalComplain = result.data.totalComplain
        this.totalDelayService = result.data.totalDelayService
        this.totalSpace = result.data.totalSpace
        this.totalFarmer = result.data.totalFarmer
        this.actualSpace = result.data.actualSpace
        this.freeSpace = result.data.freeSpace
        this.filledSpace = result.data.filledSpace
        this.series[0].data = []
        var array = []
        array.push(result.data.totalSpace)
        array.push(result.data.actualSpace)
        array.push(result.data.filledSpace)
        array.push(result.data.freeSpace)
        this.seriesData = array
      } else {
        this.totalService = 0
        this.totalComplain = 0
        this.totalDelayService = 0
        this.totalSpace = 0
        this.totalFarmer = 0
        this.actualSpace = 0
        this.freeSpace = 0
        this.filledSpace = 0
        this.seriesData = []

        this.$toast.error({
          title: 'Error',
          message: result.message,
          color: '#D6E09B'
        })
      }
      this.loading = false
      this.showData = true
    },
    searchData2 () {
      this.loadData2()
    },
    async loadData2 () {
      this.stockStatusSeriesVacantData = []
      this.stockStatusSeriesFillData = []
      this.stockStatusCategoriesData = []
      this.loading2 = true
      const result = await RestApi.getData(warehouseServiceBaseUrl, warehouseStockStatusApi, this.search2)
      if (result.success) {
        if (result.data.length) {
          const warehouseEnNameArr = []
          const warehouseBnNameArr = []
          const warehouseVacantSpaceArr = []
          const warehouseFillSpaceArr = []
          result.data.forEach(item => {
            warehouseEnNameArr.push(item.warehouse_name)
            warehouseBnNameArr.push(item.warehouse_name_bn)
            warehouseVacantSpaceArr.push(parseInt(item.free_space))
            warehouseFillSpaceArr.push(parseInt(item.filled_space))
          })
          const totalWarehouses = warehouseEnNameArr.length
          let barWidth

          if (totalWarehouses > 10) {
            barWidth = '70%'
          } else if (totalWarehouses > 5 && totalWarehouses <= 10) {
            barWidth = '40%'
          } else {
            barWidth = '15%'
          }
          this.stockStatusSeries = [{
            name: this.$t('warehouse_config.fill'),
            data: warehouseFillSpaceArr
          }, {
            name: this.$t('warehouse_config.vacant'),
            data: warehouseVacantSpaceArr
          }]
          this.stockStatusChartOptions = {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              stackType: '100%',
              width: '10%'
            },
            colors: ['#7F80FC', '#FD7171'],
            plotOptions: {
              bar: {
                columnWidth: barWidth
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: 0,
                  offsetY: 0
                }
              }
            }],
            xaxis: {
              categories: this.currentLocale === 'bn' ? warehouseBnNameArr : warehouseEnNameArr
            },
            yaxis: {
              axisBorder: {
                show: true
              },
              axisTicks: {
                show: true
              },
              labels: {
                show: true,
                formatter: function (val) {
                  return val + '%'
                }
              }
            },
            fill: {
              opacity: 1
            },
            legend: {
              position: 'right',
              offsetX: 0,
              offsetY: 50
            }
          }
        }
      }
      this.loading2 = false
      this.showData2 = true
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
      })
    },
    getRegionDistrictList2 (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          this.districtList2 = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }

      if (Id && type === 'WT') {
        list = warehouseList.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getCropNameList (cropTypeId = null) {
      const cropList = this.$store.state.warehouse.commodityNameList
      if (cropTypeId) {
        return cropList.filter(document => document.commodity_group_id === cropTypeId)
      }
      return cropList
    }
  }
}
</script>

<style>
  .mx-6 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .bg-blue {
    color: #1068c6 !important
  }
  .bg-puple {
    background: #7b73db !important;
  }
</style>
